<template>
  <div
    v-if="can('list_user')"
    id="user-list"
  >
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :group-options="groupOptions"
    />

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in userTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t('Search') }} &amp; {{ $t('Filter') }}
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="roleFilter"
            :placeholder="$t('Role')"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          />
        </v-col>

        <!-- group filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-autocomplete
            v-model="groupFilter"
            :placeholder="$t('Group')"
            :items="groupOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          />
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('Search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div
          v-if="can('manage_user','manage_system')"
          class="d-flex align-center flex-wrap"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('Add New User') }}</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :options.sync="options"
        :server-items-length="userTotal"
        :loading="loading"
        show-select
      >
        <!-- name -->
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                :src="getAvatar(item)"
              />
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'apps-user-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.first_name }}
                {{ item.last_name }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.roles`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ (item.roles || []).join(', ') }}</span>
          </div>
        </template>

        <!-- groups -->
        <template #[`item.groups`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ (item.groups || []).join(', ') }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            class="mx-2"
            icon
            fab
            small
            depressed
            :to="{name:'apps-user-view',params:{id:item.id}}"
          >
            <v-icon> {{ icons.mdiFileDocumentOutline }}</v-icon>
          </v-btn>
          <v-btn
            class="mx-2"
            icon
            color="error"
            fab
            small
            depressed
            @click="openDeleteUserModal(item.id)"
          >
            <v-icon> {{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog
        v-model="deleteUserModal"
        width="30%"
        height="auto"
        name="delete_Accounts"
      >
        <v-card>
          <v-card-title class="light">
            {{ $t('Are you sure you want to delete this user ?') }}
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="primary"
              @click="closeModal"
            >
              {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="error"
              @click="deleteUser()"
            >
              {{ $t('Delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbarShow"
        :color="snackbarColor"
        top
      >
        {{ $t(snackbarMessage) }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbarShow = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import UserListAddNew from './UserListAddNew.vue'
import userStoreModule, { USER_APP_STORE_MODULE_NAME } from '../userStoreModule'

import useUsersList from './useUsersList'

export default {
  components: {
    UserListAddNew,
  },
  methods: {
    openDeleteUserModal(id) {
      this.deleteUserModal = true
      this.selectUserId = id
    },
    getAvatar(item) {
      if (item.avatar) {
        return (this.baseUrl + item.avatar).replace(/([^:]\/)\/+/g, '$1')
      }

      return (`${this.baseUrl}/0/Default_avatar.png`).replace(/([^:]\/)\/+/g, '$1')
    },
    closeModal() {
      this.deleteUserModal = false
    },
    deleteUser() {
      store.dispatch('app-user/deleteUser', this.selectUserId).then(() => {
        this.successMessage()
        this.fetchUsers()
      }).catch(error => {
        this.errorMessage(error)
      })
      this.closeModal()
    },
    successMessage() {
      this.snackbarColor = 'success'
      this.snackbarMessage = this.successMsg
      this.snackbarShow = true
    },
    errorMessage(error) {
      this.snackbarShow = true
      this.snackbarColor = 'error'
      this.snackbarMessage = error.response.data.message
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // const deleteUser = () => {
    //   console.log('delete', this.selectUserId)
    //   store.dispatch('app-user/deleteUser', this.selectUserId)
    //   this.closeModal()
    // }
    const {
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      groupFilter,
      totalUserListTable,
      loading,
      options,
      userTotalLocal,
      selectedRows,
      roleOptions,
      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
      groupOptions,
      userTotal,
    } = useUsersList()
    const isAddNewUserSidebarActive = ref(false)

    return {
      deleteUserModal: false,
      selectUserId: null,
      userListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      groupFilter,
      totalUserListTable,
      roleOptions,
      groupOptions,
      loading,
      options,
      userTotalLocal,
      isAddNewUserSidebarActive,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserTotalIcon,
      fetchUsers,
      snackbarShow: false,
      snackbarMessage: null,
      snackbarColor: null,
      successMsg: 'Settings-Saved',

      // deleteUser,
      userTotal,
      baseUrl: process.env.VUE_APP_BASE_URL_FILE,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
