import store from '@/store'
import { i18n } from '@/plugins/i18n'
import {
  mdiAccountOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline,
} from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const userListTable = computed(() => store.getters['app-user/list'])
  const userTotal = computed(() => store.getters['app-user/total'])
  const selectedRows = computed(() => store.getters['app-user/selected'])
  const roleOptions = computed(() => store.getters['app-user/roles'].map(el => ({
    id: el.id,
    title: el.name,
    value: el.id,
  })))
  const groupOptions = computed(() => store.getters['app-user/groups'].map(el => ({
    id: el.id,
    title: el.name,
    value: el.id,
  })))

  const tableColumns = [
    { text: i18n.tc('User'), value: 'first_name' },
    { text: i18n.tc('Email'), value: 'email' },
    { text: i18n.tc('Roles'), value: 'roles', sortable: false },
    { text: i18n.tc('Groups'), value: 'groups', sortable: false },
    {
      text: i18n.tc('Actions'),
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const groupFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const userTotalLocal = ref([])

  // fetch data
  store.dispatch('app-user/fetchRoles')
  store.dispatch('app-user/fetchGroups')
  const fetchUsers = () => {
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        options: options.value,
        role: roleFilter.value,
        group: groupFilter.value,
      })
      .then(() => {
        totalUserListTable.value = 2
        setTimeout(() => {
          userTotalLocal.value = [{ total: userTotal.value, title: i18n.tc('Total Users') }]
        }, 1000)

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, roleFilter, groupFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }
  const resolveUserStatusVariant = deleted => {
    if (deleted) return 'error'

    // if (status === 'pending') return 'warning'
    // if (status === 'active') return 'success'
    // if (status === 'inactive') return 'secondary'

    return 'primary'
  }
  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    userListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    groupFilter,
    totalUserListTable,
    loading,
    options,
    userTotalLocal,
    userTotal,
    selectedRows,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserTotalIcon,
    roleOptions,
    groupOptions,
  }
}
