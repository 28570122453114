<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ $t('Add New User') }}</span>
        <v-spacer />
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="userData.first_name"
            outlined
            dense
            :rules="[validators.required]"
            :label="$t('First Name')"
            placeholder="John"
            hide-details="auto"
            class="mb-6"
          />

          <v-text-field
            v-model="userData.last_name"
            :rules="[validators.required]"
            outlined
            dense
            :label="$t('Last Name')"
            placeholder="Doe"
            hide-details="auto"
            class="mb-6"
          />

          <v-text-field
            v-model="userData.email"
            outlined
            dense
            type="email"
            :label="$t('Email')"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          />

          <v-text-field
            v-model="userData.password"
            :rules="[validators.required]"
            outlined
            dense
            counter
            :append-icon="showPassword ? icons.mdiEye :icons.mdiEyeOff "
            :type="showPassword ? 'text' : 'password'"
            :label="$t('Password')"
            placeholder="Passwort"
            hide-details="auto"
            @click:append="showPassword = !showPassword"
          />

          <v-text-field
            v-model="userData.password_confirmation"
            :rules="[validators.required]"
            outlined
            dense
            counter
            :append-icon="showConfirmPassword ? icons.mdiEye :icons.mdiEyeOff "
            :type="showConfirmPassword ? 'text' : 'password'"
            :label="$t('Confirm password')"
            :placeholder="$t('Confirm password')"
            hide-details="auto"
            @click:append="showConfirmPassword = !showConfirmPassword"
          />

          <v-select
            v-model="userData.roles"
            :label="$t('Roles')"
            :rules="[validators.required]"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            multiple
          />

          <v-select
            v-model="userData.groups"
            :label="$t('Groups')"
            :rules="[validators.required]"
            :items="groupOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            multiple
          />

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
            @click="onSubmit"
          >
            {{ $t('Add') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetUserDataAfterCancelation"
          >
            {{ $t('Close') }}
          </v-btn>
          <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            top
          >
            {{ $t(snackbarMessage) }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                {{ $t('Close') }}
              </v-btn>
            </template>
          </v-snackbar>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiClose, mdiEye,
  mdiEyeOff,
} from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { i18n } from '@/plugins/i18n'
import useUsersList from './useUsersList'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    groupOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      first_name: '',
      last_name: '',
      email: '',
      roles: null,
      groups: null,
      password_confirmation: '',
      password: '',
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }
    const containsEightCharacters = ref(false)
    const containsNumber = ref(false)
    const containsUppercase = ref(false)
    const containsSpecialCharacter = ref(false)
    const showPassword = ref(false)
    const showConfirmPassword = ref(false)
    const validPassword = ref(false)
    const snackbar = ref(false)
    const snackbarMessage = ref(null)
    const snackbarColor = ref(null)
    const successMsg = ref('Settings Saved')
    const errorMsg = ref('The password does not match the minimal requirements. Please choose a new one')
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const {
      fetchUsers,
    } = useUsersList()
    const resetUserDataAferSubmit = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
    }
    const resetUserDataAfterCancelation = () => {
      resetUserDataAferSubmit()
      emit('update:is-add-new-user-sidebar-active', false)
    }
    const checkPassword = () => {
      // eslint-disable-next-line no-useless-escape
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/

      if (userData.value.password.length >= 8) {
        containsEightCharacters.value = true
      } else {
        containsEightCharacters.value = false
      }
      containsNumber.value = /\d/.test(userData.value.password)
      containsUppercase.value = /[A-Z]/.test(userData.value.password)
      containsSpecialCharacter.value = format.test(userData.value.password)
      if (
        containsEightCharacters.value === true
        && containsSpecialCharacter.value === true
        && containsUppercase.value === true
        && containsNumber.value === true
        && userData.value.password === userData.value.password_confirmation
      ) {
        validPassword.value = true
      } else {
        validPassword.value = false
      }
    }

    const onSubmit = () => {
      checkPassword()
      if (validPassword.value) {
        store.dispatch('app-user/addUser', userData.value).then(() => {
          snackbar.value = true
          snackbarColor.value = 'success'
          snackbarMessage.value = successMsg.value
          fetchUsers()
        }).catch(error => {
          snackbar.value = true
          snackbarColor.value = 'error'
          console.log('error.response.data.message', error.response.data.message)
          const emailError = i18n.tc(error.response.data.errors.email[0])
          snackbarMessage.value = emailError
        })
        resetUserDataAferSubmit()
      } else {
        snackbar.value = true
        snackbarColor.value = 'error'
        snackbarMessage.value = i18n.tc(errorMsg.value)
      }
    }

    return {
      fetchUsers,
      resetUserDataAfterCancelation,
      resetUserDataAferSubmit,
      form,
      onSubmit,
      userData,
      valid,
      validate,
      containsEightCharacters,
      containsNumber,
      containsUppercase,
      containsSpecialCharacter,
      showPassword,
      showConfirmPassword,
      checkPassword,
      snackbar,
      snackbarMessage,
      snackbarColor,
      successMsg,
      errorMsg,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiEye,
        mdiEyeOff,
      },
    }
  },
}
</script>
