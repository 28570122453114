import axios from '@axios'

export const USER_APP_STORE_MODULE_NAME = 'app-user'

export default {
  namespaced: true,
  state: {
    list: [],
    selected: [],
    roles: [],
    groups: [],
    user: {
      id: 0,
      first_name: 'Test First Name',
      last_name: 'Test Lasta Name',
      roles: [],
      fullName: '',
      company: '',
      role: '',
      username: '',
      email: 'Test Email',
      status: 'active',
      avatar: '2.png',
    },
    total: 0,
  },
  getters: {
    list(state) {
      return state.list
    },
    total(state) {
      return state.total
    },
    selected(state) {
      return state.selected
    },
    roles(state) {
      return state.roles
    },
    groups(state) {
      return state.groups
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_USERS(state, val) {
      state.list = val
    },
    SET_TOTAL(state, val) {
      state.total = val
    },
    SET_ROLES(state, val) {
      state.roles = val
    },
    SET_GROUPS(state, val) {
      state.groups = val
    },
    SET_USER(state, val) {
      state.user = val
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return axios.get('/users', { params: queryParams }).then(response => {
        const data = []
        response.data.data.forEach(el => {
          data.push({
            id: el.id,
            fullName: el.fullName,
            first_name: el.first_name,
            last_name: el.last_name,
            company: 'Kaymbo PVT LTD',
            roles: (el.roles || []).map(r => r.name),
            username: 'bkrabbe1d',
            country: 'China',
            contact: '(397) 294-5153',
            email: el.email,
            groups: (el.groups || []).map(r => r.name),
            status: 'active',
            avatar: el.avatar,
          })
        })
        const { meta = {} } = response.data
        this.commit('app-user/SET_USERS', data)
        this.commit('app-user/SET_TOTAL', meta.total || 0)

        return response.data
      })
    },
    fetchRoles(ctx, queryParams) {
      axios.get('/roles', { params: queryParams }).then(response => {
        const data = []
        response.data.data.forEach(el => {
          data.push({
            id: el.id,
            name: el.name,
          })
        })
        this.commit('app-user/SET_ROLES', data)
      })
    },
    fetchGroups(ctx, queryParams) {
      axios.get('/groups', { params: queryParams }).then(response => {
        const data = []
        response.data.data.forEach(el => {
          data.push({
            id: el.id,
            name: el.name,
            slug: el.slug,
            description: el.description,
          })
        })
        this.commit('app-user/SET_GROUPS', data)
      })
    },
    fetchUser(ctx, queryParam) {
      axios.get(`/users/${queryParam.id}`).then(response => {
        const user = response.data.data
        const data = { ...user }
        this.commit('app-user/SET_USER', data)
      })
    },
    addUser(ctx, payload) {
      return axios
        .post('/users', { ...payload })
    },
    updateUserInfo(ctx, payload) {
      return axios
        .put(`/users/${payload.id}`, { first_name: payload.first_name, last_name: payload.last_name, email: payload.email })
        .then()
    },
    updatePassword(ctx, payload) {
      return axios
        .post(`/users/${payload.id}/password-change-admin`, { password: payload.password })
    },
    resetPassword(ctx, payload) {
      return axios
        .post(`/users/${payload.id}/password-change`, { old_password: payload.password, new_password: payload.newPassword })
    },
    updateUserGroups(ctx, payload) {
      return axios
        .put(`/users/${payload.id}`, { groups: payload.groups })
    },
    updateUserRoles(ctx, payload) {
      return axios
        .put(`/users/${payload.id}`, { roles: payload.roles })
    },
    deleteUser(ctx, id) {
      return axios
        .delete(`/users/${id}`)
    },
    updateSettings(ctx, { id, settings }) {
      return axios
        .post(`/users/${id}/settings`, { settings })
    },
    updateAvatar(ctx, payload) {
      axios
        .post(`/users/${payload.id}/avatar`, { avatar: payload.avatar }, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then()
    },
  },
}
